export const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/developpement-web',
    name: 'DevWeb',
    component: () => import('../views/DevWeb.vue')
  },
  {
    path: '/data-science',
    name: 'DataScience',
    component: () => import('../views/DataScience.vue')
  },
  {
    path: '/cyber-securite',
    name: 'CyberSecurity',
    component: () => import('../views/CyberSecurity.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/nous-rejoindre',
    name: 'NousRejoindre',
    component: () => import('../views/NousRejoindre.vue')
  },
  {
    path: '/mentions-legales',
    name: 'MentionsLegales',
    component: () => import('../views/informations/MentionsLegales.vue')
  },
]
