<template>
  <div id="app">
    <b-navbar class="base_navbar" type="dark" toggleable="md">

      <b-navbar-toggle style="color: white;" target="nav_collapse"></b-navbar-toggle>
        <b-navbar-brand :to="{ path: '/' }">
          <img id='brand_logo' :src="require('@/static/images/logo_geegz.png')" alt="Geegz">
        </b-navbar-brand>

        <b-collapse is-nav id="nav_collapse" >
          <b-navbar-nav class="ml-auto">
            <b-nav-item class="navbar_items font-alt color-text-on-black" to="/" style="color: white;"> Accueil </b-nav-item>
            <b-nav-item-dropdown class="navbar_items font-alt color-text-on-black" text="Nos expertises" right>
              <b-dropdown-item  class="navbar_dropdown_items" to="/developpement-web">Développement web</b-dropdown-item>
              <b-dropdown-item  class="navbar_dropdown_items" to="/data-science">Data science</b-dropdown-item>
              <b-dropdown-item  class="navbar_dropdown_items" to="/cyber-securite">Cybersécurité</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item class="navbar_items font-alt color-text-on-black" to="/nous-rejoindre" style="color: white;"> Nous rejoindre </b-nav-item>
            <b-nav-item class="navbar_items font-alt color-text-on-black" to="/contact" style="color: white;"> Nous contacter </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
    </b-navbar>




    <router-view/>




  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
/*
COLOR :
black
white
#A3A3A3 : gris sur blacn
#D8D8D8 (text on black)
#6E6E6E (text on white)
 */
html, body { height: 100%; scroll-behavior:smooth;}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  src: url("./static/fonts/open-sans/OpenSans-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Magneto";
  font-style: normal;
  src: url("./static/fonts/magneto/MAGNETOB.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Dosis";
  font-style: normal;
  src: url("./static/fonts/dosis/Dosis-Medium.ttf");
  font-display: swap;
}


body {
  font-family: Open Sans, sans-serif !important;
  font-size: 1.1em !important;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

.font-alt {
  font-family: Dosis, sans-serif !important;
}

.color-text-on-black {
  color: #D8D8D8 !important;
}
.color-text-on-white {
  color: #6E6E6E !important;
}


.base_navbar {
  height: 70px;
  background-color: black !important;
}

#brand_logo {
  height: 60px;
}

#nav_collapse {
  background-color: black;
  z-index: 1001;
}

.navbar_items {
  font-size: 20px;
  margin-left: 10px;
}

.nav-link {
  color: white ;
}

.form_div {
  background: #FFFFFF;
  padding-left: 15px;
  padding-bottom: 60px;
  padding-top: 15px;
  border-style: solid;
  border-color: #BDBDBD;
  border-width: 0.5px;
  margin-top: 15px;
  margin-bottom: 100px;
}

.form_div_title{
  color: #FFFFFF;
  font-size: calc(1.2em + 0.8vw);
  margin-top: -90px;
}

.form_content {
  margin-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 16px;
  color: #6E6E6E;
}

.section-title {
  font-size: 26px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}
.section-title-margin {
  margin-top: 80px;
  margin-bottom: 50px;
}

.section-slogan {
  font-size: 18px;
  text-align: justify;
}

.section-explanation {
  font-size: 14px;
  text-align: justify;
}

.text-explanation-on-black {
  color: #D8D8D8;
}

.text-explanation-on-white {
  color: #6E6E6E;
}

.basic-alt-tabs {
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.1em;
  border: none;
}


@media (min-width: 480px) {
  #brand_logo {
    height: 65px;
  }
  .form_content {
    font-size: 18px;
  }
  .section-title {
    font-size: 30px;
    letter-spacing: 0.3em;
  }
  .section-slogan {
    font-size: 22px;
  }
  .section-explanation {
    font-size: 16px;
  }
  .basic-alt-tabs {
    font-size: 18px;
    letter-spacing: 0.2em;
  }
}


@media (min-width: 1024px) {
  .base_navbar {
    height: 80px !important;
  }
  #brand_logo {
    height: 80px;
  }
  .navbar_items {
    font-size: 24px;
  }
}

</style>
